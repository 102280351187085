<template>
    <div class="container">
        <div class="head">
            <div class="up">
                <img :src="theme.img" alt="">
                <div class="block">
                    <span class="title"> {{theme.title}}</span>
                </div>
            </div>
            <div class="down">
             {{theme.content}}
            </div>
        </div>
        <div class="content">
            <div class="items">
                <div class="item" v-for="article in articles" :key="article.id">
                    <div class="img"  @click="gotoArticleDetail(article.id)" >
                        <img :src="article.img" alt="">
                    </div>
                    <div class="info">
                        <div @click="gotoArticleDetail(article.id,article.hotNum)" class="title">{{article.title}}</div>
                        <div  class="intro">{{article.intro}}</div>
                        <div class="other-info">
                            <span class="author">{{article.authorName}}</span>
                             <div class="time-info">
                                 <span class="time-icon"></span> 
                                 <span class="time">{{article.time}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pageNum">
                <el-pagination
                background
                :page-size="limit"
                @current-change="changePage"
                layout="prev, pager, next"
                :total="count">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {DELETE,QUERY,UPDATE,UPDATES}  from '@/services/dao.js';
import { showTimeFormat,formatDate } from '@/js/common';
export default {
    data(){
        return {
            id:'',
            theme:{
                id:0,
                img:"",
                title:"",
                content:""
            },
            articles:[],
            limit:10,  //每页数据
            page:1,  //当前页
            count:0   //数据总数
        }
    },
    created(){
        this.id=this.$route.query.id;
        this.init();
    },
    methods:{
        init(){
            this.getThemeInfo();
            this.getArticles();
        },
        //获取主题信息
       async getThemeInfo(){
            let info=  await  QUERY("post","",'Theme(where: {id: {_eq: '+this.id+'}}){    content    createTime    id    img    title  }');
            this.theme={ 
                id:info.data.Theme[0].id,
                img:this.api.LoginURL.concat(info.data.Theme[0].img),
                title:info.data.Theme[0].title,
                content:info.data.Theme[0].content
            };
            
        },
        //获取主题对应的文章
       async getArticles(){  
           let sql = 'Article(limit:'+this.limit+',offset:'+(this.limit*(this.page-1))+',where: {theme: {_eq: '+this.id+'}}) {coverImg id img intro title authorName  createTime  hotNum }'
            sql += ' Article_aggregate(where: {theme: {_eq: '+this.id+'}}) {aggregate {count}}'
            let info =  await  QUERY(
                "post","",sql
            );
            this.count = info.data.Article_aggregate.aggregate.count
            let arr = []
           for(let i=0;i<info.data.Article.length;i++){
                let imgUrl = info.data.Article[i].coverImg
                if(imgUrl.indexOf('http')<=-1)imgUrl = this.api.LoginURL.concat(imgUrl)
                arr.push({
                    title:info.data.Article[i].title,
                    intro:info.data.Article[i].intro,
                    img:imgUrl,
                    id:info.data.Article[i].id,
                    authorName:info.data.Article[i].authorName,
                    time:showTimeFormat(info.data.Article[i].createTime),
                    hotNum: info.data.Article[i].hotNum
                });
            }
            this.articles = arr
        },
        async gotoArticleDetail(id,hotNum){
            hotNum+=1
            let update_hotNum = await UPDATES("POST","",'update_TtArticle(where:{id:{_eq:'+id+'}},_set:{hotNum:'+hotNum+'}) {affected_rows}');
            console.log(update_hotNum)
           this.$router.push({path:"/articleDetail",query:{
                id:id
           }});
        },

        changePage(e){
            console.log(e);
            this.page = e
            this.getArticles()
        }
    }

}
</script>

<style scoped>
    .container{
        width: 1024px;
        background-color: #ffffff;
        padding: 30px;
        margin: 0 auto;
    }
    .head{
        /* border: 1px solid red; */
        padding: 0;
        background-color: #ffffff;
    }
    .head .up{
        height: 300px;
        width: 100%;
        position: relative;
        /* border: 1px solid red; */
    }
    .head .up img{
        width: 100%;
        height:100%;
    }
    .head .up .block{
        width: 100%;
        position: absolute;
        bottom: 30px;
        height: 40px;
        font-size: 30px;
        font-weight: 800;
        color: rgba(255, 255, 255, 0.92);
    }
    .head .up .block .title{
        padding:0 40px;
    }
    .head .up .block .share{
        display: inline-block;
        position: absolute;
        right: 30px;
        color:rgba(255, 255, 255, 1);
    }
    .head .down{
        width: 92%;
        background-color: #EDF1F4;
        margin-top: 10px;
        padding: 20px 40px;
        font-size: 16px;
    }
    .head .down .douhao-icon{
        display: inline-block;
        width: 90px;
        height: 90px;
        vertical-align: top;
    }
    .container .content{
        margin-top: 50px;
    }

    .items .item{
        margin-top: 10px;
        height: 110px;
    }
    .items .item .img{
        width: 160px;
        height: 100%;
        display: inline-block;
    }
    .items .item .img:hover{
        cursor: pointer;
    }
     .items .item .img img{
         width: 100%;
         height: 100%;
     }
    .items .item .info{
        width: 840px;
        height: 100%;
        display: inline-block;
        position: relative;
        vertical-align: top;
        margin-left:10px ;
    }
    .items .item .info .title{
        color: #5e6b73;
        font-size: 18px ;
        line-height:24px;
        font-weight: 600;
    }
    .items .item .info .title:hover{
        cursor: pointer;
        color: brown;
    }
    .items .item .info .intro,.items .item .info .other-info{
        color: #5e6b73;
        font-size: 14px ;
    }
    .items .item .info .other-info{
         position: absolute;
         bottom: 5px;
         width: 100%;
    }
    .items .item .info .other-info .author:hover{
        cursor: pointer;
        color:rgb(243, 162, 132);
    }
    .items .item .info .other-info .time-info{
       float: right;
        
    }
    .items .item .info .other-info .time-info .time{
        margin-left: 10px;
    }
    .items .item .info .other-info .time-info .time-icon{
        display: inline-block;
        width: 10px;
        background-repeat: no-repeat;
        height: 10px;
        background-image: url(~@/assets/icon/time-icon.png);
    }
    .pageNum{
        width: 100%;
        padding-top: 20px;
        display: flex;
        justify-content: center;
    }
</style>